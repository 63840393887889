<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showCmd"
        max-width="1300px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer></v-spacer>
            <v-radio-group
              v-model="editedItem.type_id"
              row
              mandatory
              @change="boms = []"
              dense
              :readonly="!modify || boms.length > 0"
              v-if="!produit && code_app == 4"
            >
              <v-radio label="Fabrication" value="0"></v-radio>
              <v-radio label="Transformation" value="1"></v-radio>
            </v-radio-group>

            <v-spacer></v-spacer>
            Statut : {{ editedItem.statut_name }}
          </v-card-title>

          <v-card-text v-if="editedItem.type_id">
            <v-container fluid>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  v-if="editedItem.statut_id == 6 && editedItem.type_id == '1'"
                >
                  <v-autocomplete
                    ref="gammes"
                    :items="gammes.filter((elm) => elm.sp == 1)"
                    v-model="editedItem.gamme_id"
                    :label="'Process'"
                    :item-value="'id'"
                    :item-text="'name'"
                    :filter="customFilter"
                    :rules="[(v) => !!v || 'Process obligatoire']"
                    dense
                    @change="gamme_change"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  :sm="editedItem.type_id == '0' ? 12 : 10"
                  :md="editedItem.type_id == '0' ? 12 : 10"
                  v-if="!produit && editedItem.statut_id == 6"
                >
                  <v-autocomplete
                    ref="products"
                    :items="
                      editedItem.type_id == '0' ? man_products : products_list
                    "
                    v-model="product"
                    :label="'Produit'"
                    :item-value="
                      editedItem.type_id == '0' ? 'id' : 'produit_id'
                    "
                    :item-text="
                      editedItem.type_id == '0' ? 'label' : 'product_name'
                    "
                    :filter="customFilter"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                    return-object
                    @change="get_boms"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (editedItem.type_id == "0"
                          ? item.code
                          : item.product_code) +
                        "-" +
                        (editedItem.type_id == "0"
                          ? item.label
                          : item.product_name)
                      }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            (editedItem.type_id == "0"
                              ? data.item.code
                              : data.item.product_code) +
                            "-" +
                            (editedItem.type_id == "0"
                              ? data.item.label
                              : data.item.product_name)
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            'Commande N° ' +
                            data.item.nocmd +
                            ' de ' +
                            data.item.tier_name
                          "
                          v-if="data.item.nocmd"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-html="
                            'Quantité Cmdée ' +
                            data.item.qte +
                            ' ' +
                            data.item.unit
                          "
                          v-if="data.item.nocmd"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="pb-0">
                  <v-btn
                    color="indigo"
                    @click="definir_output"
                    v-if="
                      editedItem.statut_id == '6' && editedItem.type_id == '1'
                    "
                    :disabled="product.id == null"
                    :loading="listload"
                    :dark="product.id != null"
                  >
                    <v-icon left>mdi-export</v-icon>
                    Output
                  </v-btn></v-col
                >
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" v-if="product.nocmd">
                  Commande :
                  {{ product.nocmd }}
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="product.nocmd">
                  Client :
                  {{ product.tier_name }}
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  Date :
                  {{ datefr(editedItem.date_order) }}
                </v-col>
                <v-col cols="12" sm="6" md="1">
                  Code :
                  <a @click="gotoproduct">{{ product.code }}</a>
                </v-col>
                <v-col cols="12" sm="6" md="11">
                  Produit :
                  {{ product.label }}
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    autocomplete="off"
                    dense
                    class="inputPrice"
                    v-model="editedItem.order_ref"
                    label="Ref. "
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        autocomplete="off"
                        dense
                        ref="qte"
                        class="inputPrice"
                        type="number"
                        v-model.number="editedItem.qte_lanc"
                        :label="
                          'Quantite ' +
                          (product.unit_name
                            ? '(' + product.unit_name + ')'
                            : product.unit
                            ? '(' + product.unit + ')'
                            : '')
                        "
                        :rules="[
                          (v) => !!v || 'Quantite obligatoire',
                          (v) => !v || v > 0 || 'Quantite obligatoire',
                          (v) =>
                            v > 0 ||
                            v >= qte_min ||
                            editedItem.statut_id != 6 ||
                            code_app == 4 ||
                            'Quantite Min ' + qte_min,
                          (v) =>
                            parseFloat(v) <= parseFloat(qte_max) ||
                            editedItem.statut_id != 6 ||
                            (code_app == 4 && editedItem.type_id == '0') ||
                            '  Max ' + numberWithSpace(qte_max),
                        ]"
                        :hint="
                          reste
                            ? 'Reste à lancer ' +
                              numberWithSpace(reste > 0 ? reste : 0)
                            : ''
                        "
                        :readonly="!modify || is_reserved"
                        v-bind="attrs"
                        v-on="on"
                        @input="bom_change"
                        :disabled="!product.id"
                        hide-spin-buttons
                        autofocus
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </template>
                    <li v-if="product.qte">
                      Commandée : {{ numberWithSpace(product.qte) }}
                    </li>
                    <li v-if="product.qte">
                      Lancée :
                      {{ numberWithSpace(product.qte_lanc) }}
                    </li>
                    <li v-if="product.qte">
                      Reste a lancer :
                      {{ numberWithSpace(product.qte - product.qte_lanc) }}
                    </li>
                    <li>
                      Fabriquée :
                      {{ numberWithSpace(product.qte_fab) }}
                    </li>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    dense
                    class="inputPrice"
                    type="number"
                    v-model.number="editedItem.delai"
                    :label="'Délai Fabrication (jours)'"
                    :readonly="!modify"
                    :rules="[
                      (v) =>
                        !v ||
                        (v > 0 &&
                          (product_delai
                            ? verif_date(
                                editedItem.date_order,
                                v,
                                product_delai
                              )
                            : true)) ||
                        'Date Max ' + datefr(product_delai),
                    ]"
                    hide-spin-buttons
                    :hint="
                      product_delai
                        ? 'Delai Produit : ' + datefr(product_delai)
                        : ''
                    "
                    @input="addDays"
                    @focus="$event.target.select()"
                  ></v-text-field>
                  {{ duedate ? "Avant le : " + duedate : "" }}
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    autocomplete="off"
                    ref="tol"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.tol"
                    :readonly="!editer || editedItem.statut_id == '9'"
                    :label="'Mise au mille(%)'"
                    :rules="[
                      (v) => !v || v >= 0 || v < 100 || 'Valeur incorrecte',
                    ]"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  v-if="articles_list.length != 1 && editedItem.type_id == '0'"
                >
                  <v-select
                    ref="article"
                    :items="articles_list"
                    v-model="editedItem.article_id"
                    :label="'Article'"
                    :item-value="'id'"
                    :item-text="'label'"
                    :rules="[(v) => !!v || 'Article obligatoire']"
                    dense
                    :readonly="!modify"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    required
                    :readonly="!modify"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-tabs v-model="tabs" class="my-tabs">
                    <v-tab key="1" href="#1">
                      {{
                        editedItem.statut_id == "6"
                          ? "Reservation " +
                            (arrivage ? "(y compris arrivages) " : "")
                          : "Matiére Premiére"
                      }}
                    </v-tab>
                    <v-tab key="2" href="#2" v-if="editedItem.type_id == '0'">
                      Quantité {{ "(" + product.unit + ")" }}
                    </v-tab>
                    <v-tab key="3" href="#3" v-if="editedItem.statut_id == '6'">
                      Stocks
                    </v-tab>
                    <v-tab key="4" href="#4" v-if="editedItem.type_id == '1'">
                      Outputs
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabs">
                    <v-tab-item :value="'1'">
                      <v-row v-if="editedItem.statut_id == '6'">
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          class="text-bold text-right py-4"
                        >
                          <span
                            :key="kb"
                            :class="
                              qte_fais == 0
                                ? 'red--text text-bold text-right py-4'
                                : qte_fais >= editedItem.qte_lanc
                                ? 'green--text text-bold text-right py-4'
                                : 'orange--text text-bold text-right py-4'
                            "
                          >
                            {{
                              "Quantité faisable : " +
                              numberWithSpace(
                                qte_fais ? qte_fais.toFixed(2) : "0.00"
                              ) +
                              " (" +
                              product.unit +
                              ")"
                            }}</span
                          >
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-row>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                              Article
                            </v-col>
                            <v-col
                              cols="12"
                              sm="1"
                              md="1"
                              class="text-center"
                              >{{
                                editedItem.type_id == "0" ? "Ratio" : ""
                              }}</v-col
                            >
                            <v-col cols="12" sm="2" md="2" class="text-right">
                              Qte Reservée
                            </v-col>
                            <v-col cols="12" sm="1" md="1" class="text-right">
                              Besoin
                            </v-col>
                          </v-row>
                          <v-form ref="form22" v-model="valid" lazy-validation>
                            <v-row v-for="bom in boms" :key="bom.id" dense>
                              <v-col cols="12" sm="1" md="1">
                                <v-btn
                                  icon
                                  color="blue darken-1"
                                  @click="bom_add(bom)"
                                  :key="kp2"
                                  v-if="
                                    bom.add_mode == false &&
                                    qte_reserve(bom) < bom.besoin &&
                                    add_mode == false &&
                                    editedItem.qte_lanc
                                  "
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </v-col>

                              <v-col
                                cols="12"
                                sm="5"
                                md="5"
                                v-if="bom.add_mode == false"
                                >{{ bom.caption }}
                              </v-col>

                              <v-col
                                cols="12"
                                sm="4"
                                md="4"
                                v-if="bom.add_mode == true"
                              >
                                <v-autocomplete
                                  :items="
                                    bom.products.filter(
                                      (elm) =>
                                        !bom.articles
                                          .map((elm) => elm.article_id)
                                          .includes(elm.id) &&
                                        elm.stock_dispo > 0
                                    )
                                  "
                                  v-model="bom.article_id"
                                  :item-text="
                                    editedItem.type_id == '0'
                                      ? 'article_label'
                                      : 'label'
                                  "
                                  :item-value="'id'"
                                  :label="'Article'"
                                  :rules="[(v) => !!v || 'Article obligatoire']"
                                  outlined
                                  dense
                                  @change="article_change(bom)"
                                  :key="kp"
                                >
                                  <template v-slot:item="{ item }">
                                    <v-list max-width="700">
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >{{
                                            editedItem.type_id == "0"
                                              ? item.article_label
                                              : item.label
                                          }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle
                                          v-html="'Ref. : ' + item.ref"
                                          v-if="item.ref"
                                        ></v-list-item-subtitle>

                                        <v-list-item-subtitle
                                          v-html="
                                            'Stock Dispo : ' +
                                            numberWithSpace(item.stock_dispo) +
                                            ' ' +
                                            (item.unit ? item.unit : '')
                                          "
                                        ></v-list-item-subtitle>
                                        <v-list-item-subtitle
                                          :class="
                                            item.stock_dispo >= bom.besoin
                                              ? 'green'
                                              : item.stock_dispo == 0
                                              ? 'red'
                                              : 'orange'
                                          "
                                          v-html="
                                            'Quantité faisable : ' +
                                            numberWithSpace(
                                              qte_fais_int(bom, item)
                                            ) +
                                            ' ' +
                                            (product.unit ? product.unit : '')
                                          "
                                        ></v-list-item-subtitle>
                                        <v-list-item-subtitle
                                          v-html="
                                            'Fournisseur : ' + item.tier_name
                                          "
                                        ></v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="1"
                                md="1"
                                v-if="bom.add_mode == true"
                              >
                                <v-text-field
                                  autocomplete="off"
                                  type="number"
                                  ref="qte"
                                  v-model.number="bom.qte"
                                  :label="
                                    'Qte (' +
                                    (bom.support == 1 ? 'ml' : bom.unit_name) +
                                    ')'
                                  "
                                  :rules="[
                                    (v) => !!v || 'Quantité obligatoire',
                                    (v) =>
                                      v <=
                                        bom.stock_dispo -
                                          qte_reserve_tmp(bom) ||
                                      'Stock Dispo ' +
                                        (bom.stock_dispo -
                                          qte_reserve_tmp(bom)),
                                    (v) =>
                                      v <= bom.besoin - qte_reserve(bom) ||
                                      'Reservation Rest. ' +
                                        (bom.besoin - qte_reserve(bom)),
                                  ]"
                                  dense
                                  outlined
                                  :disabled="!bom.article_id"
                                  @focus="$event.target.select()"
                                  @keydown.enter="bom_save(bom)"
                                  hide-spin-buttons
                                ></v-text-field>
                              </v-col>

                              <v-col
                                cols="12"
                                sm="1"
                                md="1"
                                v-if="bom.add_mode"
                              >
                                <v-row no-gutters>
                                  <v-col cols="12" md="6">
                                    <v-btn
                                      icon
                                      color="blue darken-1"
                                      @click="bom_save(bom)"
                                    >
                                      <v-icon>mdi-content-save-outline</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-btn
                                      icon
                                      color="blue darken-1"
                                      @click="bom_close(bom)"
                                    >
                                      <v-icon>mdi-close-box-outline</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-col>

                              <v-col cols="12" sm="1" md="1">
                                <v-text-field
                                  autocomplete="off"
                                  type="number"
                                  v-model.number="bom.ratio"
                                  :label="
                                    '(' +
                                    (bom.support == 1
                                      ? 'ml'
                                      : bom.ratio_unit_name) +
                                    ')'
                                  "
                                  dense
                                  outlined
                                  @input="bom_change"
                                  @focus="$event.target.select()"
                                  hide-spin-buttons
                                  v-if="editedItem.type_id == '0'"
                                ></v-text-field>
                              </v-col>

                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                class="text-bold text-right py-4"
                              >
                                <span :key="kb">
                                  {{
                                    numberWithSpace(qte_reserve(bom)) +
                                    " (" +
                                    bom.unit_name +
                                    ")"
                                  }}</span
                                >
                              </v-col>
                              <v-col
                                cols="12"
                                sm="1"
                                md="1"
                                :class="
                                  qte_reserve(bom) == 0
                                    ? 'red--text text-bold text-right py-4'
                                    : bom.besoin > qte_reserve(bom)
                                    ? 'orange--text text-bold text-right py-4'
                                    : 'green--text text-bold text-right py-4'
                                "
                              >
                                <span :key="kb">
                                  {{
                                    numberWithSpace(bom.besoin) +
                                    " (" +
                                    bom.unit_name +
                                    ")"
                                  }}</span
                                >
                              </v-col>

                              <v-col cols="12" sm="12" md="12" :key="kp2">
                                <v-row
                                  v-for="(article, index) in bom.articles"
                                  :key="index"
                                  dense
                                >
                                  <v-col cols="12" sm="4" md="4"></v-col>
                                  <v-col cols="12" sm="4" md="4"
                                    >{{
                                      article.article_code +
                                      "-" +
                                      article.article_label
                                    }}
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1">
                                    {{
                                      " Qte: " +
                                      numberWithSpace(article.qte) +
                                      " " +
                                      bom.unit_name
                                    }}
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="2"
                                    md="2"
                                    v-if="bom.support == 1 && bom.perte"
                                    class="text-bold text-right py-4"
                                  >
                                    <span :key="kb">
                                      {{
                                        "Exces Laize : " +
                                        numberWithSpace(
                                          bom.laize
                                            ? bom.laize.toFixed(2)
                                            : "0.00"
                                        ) +
                                        bom.laize_unit_name +
                                        " (" +
                                        bom.perte +
                                        "%" +
                                        ")"
                                      }}</span
                                    >
                                  </v-col>
                                  <v-col cols="12" sm="1" md="1">
                                    <v-btn
                                      icon
                                      color="blue darken-1"
                                      @click="delete_article(bom, article)"
                                    >
                                      <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-col>
                      </v-row>
                      <listitems
                        :list="list_boms"
                        :headers="bomsheaders"
                        :key="fl"
                        :master="true"
                        :add="false"
                        :del="false"
                        :exp_excel="false"
                        :showedit="false"
                        :showexp="editedItem.statut_id == '6'"
                        :exp_headers="boms_articles_headers"
                        :hdh_exp="false"
                        :exp_data="intrant.articles"
                        :val_exp="'qte'"
                        :mes_exp="'Articles réservés'"
                        :laoding="progress"
                        v-if="editedItem.statut_id != '6'"
                      >
                      </listitems>
                    </v-tab-item>
                    <v-tab-item :value="'2'">
                      <listitems
                        :list="
                          editedItem.statut_id == '6' ? gammes_list : gammes
                        "
                        :headers="gammesheaders"
                        :key="kgamme"
                        :master="true"
                        :add="false"
                        :Update="editer && editedItem.statut_id != '9'"
                        :del="false"
                        :exp_excel="false"
                        :showstd="true"
                        :laoding="progress"
                      >
                      </listitems>
                    </v-tab-item>
                    <v-tab-item :value="'3'">
                      <listitems
                        :list="stock_list"
                        :headers="stockheaders"
                        :key="kstk"
                        :master="true"
                        :add="false"
                        :del="false"
                        :exp_excel="false"
                        :showedit="false"
                      >
                      </listitems>
                    </v-tab-item>
                    <v-tab-item :value="'4'">
                      <listitems
                        :list="articles_sel"
                        :headers="outputs_headers"
                        :key="kstk"
                        :master="true"
                        :add="false"
                        :del="false"
                        :exp_excel="false"
                        :showedit="false"
                      >
                      </listitems>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-alert v-model="alert" :type="type_alert" dismissible>{{
                message
              }}</v-alert>
              <v-spacer></v-spacer>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>

            <v-btn
              color="blue darken-1"
              text
              @click="traiter_order"
              v-if="
                ((product.id && valider && editedItem.statut_id == '6') ||
                  (solder && editedItem.statut_id == '7')) &&
                editedItem.type_id
              "
            >
              {{ editedItem.statut_id == "6" ? "Valider" : "Solder" }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <articleoutputform
      :articles="articles_outputs"
      :product_in="product"
      :showForm="!isFOClose"
      :attributes="attributes"
      :key="aof"
      @save="save_def"
      @close="isFOClose = true"
    >
    </articleoutputform>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>

<script>
function sortByAttributeValue(attributeId) {
  return function (a, b) {
    // Parse attributs_json strings to JSON objects
    let attributsJsonA = JSON.parse(a.attributs_json);
    let attributsJsonB = JSON.parse(b.attributs_json);

    // Find values of the given attributeId
    let valueA =
      attributsJsonA.find((obj) => obj.attribute_id === attributeId)?.value ||
      0;
    let valueB =
      attributsJsonB.find((obj) => obj.attribute_id === attributeId)?.value ||
      0;

    // Compare values and return result
    return valueA - valueB;
  };
}

import GETPRODUCTBOM from "../graphql/Production/GetProductBom.gql";
import INSERT_ORDER from "../graphql/Production/INSERT_ORDER.gql";
import UPDATE_ORDER from "../graphql/Production/UPDATE_ORDER.gql";
import PRODUCT_LIST_TRANSFORME from "../graphql/Product/PRODUCT_LIST_TRANSFORME.gql";
import ARTICLES from "../graphql/Commande/ARTICLES.gql";
import { arrivage } from "print/data.js";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    listitems: () => import("./ListItems.vue"),
    articleoutputform: () => import("./ArticleOutputForm.vue"),
  },
  name: "orderform",
  props: {
    item: Object,
    showForm: Boolean,
    editer: Boolean,
    valider: Boolean,
    solder: Boolean,
    code_app: Number,
    produit: Object,
    units: Array,
    templates: Array,
    attributes: Array,
    boms_list: Array,
    man_products: Array,
    gammes: Array,
  },
  data: () => ({
    is_reserved: false,
    add_mode: false,
    boms: [],
    products_list: [],
    qte_fais: 0,
    title: "",
    boms_articles: [],
    isListClose: true,
    btn_save: true,
    width: "1200px",
    tabs: "0",
    product: {},
    duedate: null,
    product_delai: null,
    st: 100,
    fl: 200,
    plf: 300,
    kgamme: 400,
    kstk: 500,
    frml: 600,
    kb: 700,
    kp: 800,
    kp2: 900,
    aof: 2000,
    isFOClose: true,
    progress: false,
    valid: true,
    modify: false,
    datepicker1: false,
    isProductClose: true,
    listload: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      id: -1,
      statut_name: "Brouillon",
      unit_name: "Quantité",
      statut_id: "6",
      date_order: null,
      qte_lanc: 0,
    },
    editedItem: {},
    reste: 0,

    gammes_list: [],
    stock_list: [],
    list_headers: [],
    articles_outputs: [],
    stockheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        slot: "href",
        selected: true,
      },

      {
        text: "Unité",
        value: "unit_name",
        selected: true,
      },

      {
        text: "Stock",
        value: "stock_dispo",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
    ],

    gammesheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Etape",
        value: "name",
        slot: "href",
        selected: true,
      },
      {
        text: "Durrée(H)",
        value: "duration",
        align: "end",
        slot: "cur",
        selected: true,
        dec: 2,
      },
      {
        text: "Qte Prévue",
        value: "qte",
        align: "end",
        slot: "cur",
        selected: true,
        edit: true,
        type: "qte",
        md: 6,
        sd: 6,
        dec: 2,
      },
      {
        text: "Qte Fab",
        value: "qte_fab",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Taux Fab.",
        value: "prc_fab",
        slot: "progress",
        align: "center",
        selected: true,
        dec: 2,
      },
      {
        text: "Tolérence(%)",
        value: "tol",
        slot: "cur",
        align: "end",
        selected: true,
        edit: true,
        type: "number",
        md: 6,
        sd: 6,
        dec: 2,
      },
    ],
    bomsheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },

      {
        text: "Unité",
        value: "unit",
        selected: true,
      },
      {
        text: "Qte réserve",
        value: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Qte Liv",
        value: "qte_liv",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Taux Liv.",
        value: "prc_liv",
        slot: "progress",
        align: "center",
        selected: true,
        dec: 2,
      },
      {
        text: "Qte Consommée",
        value: "qte_cons",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Taux Cons.",
        value: "prc_cons",
        slot: "progress",
        align: "center",
        selected: true,
        dec: 2,
      },
      // {
      //   text: "Tolérence(%)",
      //   value: "tol",
      //   slot: "",
      //   align: "end",
      //   selected: true,
      //   dec: 2,
      // },
    ],

    boms_articles_headers: [
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "tier_name",
        selected: true,
      },
      {
        text: "Stock",
        value: "stock_dispo",
        align: "end",
        selected: true,
        slot: "cur",
      },
      {
        text: "Reservé",
        value: "stock_reserve",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Arrivage",
        value: "cmd_fournisseur",
        slot: "cur",
        align: "end",
        hiden: true,
        selected: true,
        dec: 2,
      },
      {
        text: "Disponible",
        value: "stock_dispo_c",
        slot: "cur",
        align: "end",
        selected: true,
        total: "stock_dispo_c",
        dec: 2,
        expire: true,
        cloture: true,
      },
      {
        text: "Qte Rsrv.",
        value: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
    ],
    outputs_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "product_code",
        selected: true,
      },
      {
        text: "Produit",
        value: "product_label",
        selected: true,
      },
      {
        text: "Nombre",
        value: "ratio",
        selected: true,
      },
    ],
    articles_sel: [],
    besoins: [],
    intrant: {},
    message: "",
    alert: false,
    type_alert: "info",
    isfaisable: true,
    tol: 0,
    qte: 0,
    qte_max: 0,
  }),

  computed: {
    arrivage() {
      return arrivage;
    },
    qte_min() {
      let e = this.editedItem.qte_encours ? this.editedItem.qte_encours : 0;
      let f = this.editedItem.qte_fab ? this.editedItem.qte_fab : 0;
      return e + f;
    },
    articles_list() {
      let l = [];
      if (this.product) {
        if (this.product.articles)
          l = this.product.articles.filter((elm) => elm.tier_id == 1);
      }
      return l;
    },
    links() {
      let l = [];
      let i = this.articles_list.findIndex(
        (elm) => elm.id == this.editedItem.article_id
      );
      if (i >= 0) l = this.articles_list[i].links;

      return l;
    },
    list_boms() {
      return this.item ? this.item.boms : [];
    },

    showCmd() {
      return this.showForm;
    },

    formTitle() {
      let t =
        this.code_app == 4
          ? this.editedItem.type_id == "0"
            ? "Ordre de Fabrication "
            : "Ordre de Transformation "
          : "Commande Interne ";
      return this.item
        ? this.item.id > 0
          ? t + "N° : " + this.editedItem.no_order
          : "Ajout " + t
        : "";
    },
  },
  watch: {},

  created() {},
  async mounted() {
    if (this.produit) {
      this.product = this.produit;

      this.qte_max = this.product.qte - this.product.qte_lanc;

      this.qte = this.qte_max;
    }
    this.boms_articles_headers[5].hiden = !this.arrivage;
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.product.produit_id = this.editedItem.produit_id;
        this.product.code = this.editedItem.code;
        this.product.label = this.editedItem.label;
        this.product.id = this.editedItem.cmd_detail_id
          ? this.editedItem.cmd_detail_id
          : this.editedItem.produit_id;
        this.articles_sel = this.editedItem.outputs;
        this.kstk++;
      } else {
        this.defaultItem.date_order = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);

        if (this.produit) {
          this.editedItem.type_id = "0";
          this.editedItem.qte_lanc = this.product.rest_lanc;
        } else this.editedItem.type_id = "0";
        if (this.gammes)
          if (this.gammes.filter((elm) => elm.sp == 1).length == 1) {
            this.editedItem.gamme_id = this.gammes.filter(
              (elm) => elm.sp == 1
            )[0].id;
            this.gamme_change();
          }
        this.get_boms();
      }

      //
      this.addDays();
      //
      this.modify = this.editer && this.editedItem.statut_id == "6";
    }
    if (this.editedItem.statut_id == "6") {
      this.gammesheaders[1].slot = "href";
      this.gammesheaders[3].edit = true;
    } else {
      this.gammesheaders[1].slot = "";
      this.gammesheaders[3].edit = false;
    }
    if (
      (this.$store.state.auth.includes("02064") || this.$store.state.isadmin) &&
      this.editedItem.statut_id != "9"
    ) {
      // this.bomsheaders[8].slot = "href3";
      this.gammesheaders[6].slot = "href3";
    }
  },

  methods: {
    definir_output() {
      this.isFOClose = false;
      this.aof++;
    },
    save_def(items) {
      this.articles_sel = [];
      items.forEach((element) => {
        let i = this.articles_sel.findIndex(
          (elm) => elm.id == element.article_id
        );
        if (i >= 0) {
          this.articles_sel[i].ratio = this.articles_sel[i].ratio + 1;
        } else
          this.articles_sel.push({
            id: element.article_id ? element.article_id : element.id,
            article_id: element.article_id,
            product_label: element.article ? element.article.label : null,
            product_code: element.article ? element.article.code : null,
            caption: element.caption,
            ratio: 1,
          });
      });
      this.isFOClose = true;
    },
    async gamme_change() {
      let i = this.gammes.findIndex(
        (elm) => elm.id == this.editedItem.gamme_id
      );

      if (i >= 0) {
        this.products_list = this.gammes[i].boms;
      }
    },
    qte_reserve_tmp(bom) {
      let q = 0;
      for (let index = 0; index < this.boms.length; index++) {
        const element = this.boms[index];
        element.articles.forEach((elm) => {
          if (elm.article_id == bom.article_id) {
            q = q + parseFloat(elm.qte);
          }
        });
      }
      return q;
    },
    qte_fais_int(bom, item) {
      let s =
        ((item.stock_dispo - this.qte_reserve(bom)) *
          this.editedItem.qte_lanc) /
        bom.besoin;
      return s ? s.toFixed(2) : "0.00";
    },
    qte_reserve(bom) {
      let s = 0;
      if (bom.articles)
        s = bom.articles.reduce((a, b) => a + (b["qte"] || 0), 0);
      return s;
    },

    async gotoproduct() {
      await this.$router
        .push({
          name: "product_tosell",
          params: {
            produit_id: this.product.produit_id,
            of_id: this.editedItem.id,
            name: "suivi_cmds_c",
            filtre: this.product.code,
          },
        })
        .catch(() => {});
    },

    bom_change() {
      this.qte = this.editedItem.qte_lanc;
      this.reste =
        this.product.qte -
        this.product.qte_lanc -
        (this.editedItem.qte_lanc ? this.editedItem.qte_lanc : 0);
      this.boms.forEach((bom) => {
        this.product_change(bom);
      });
    },
    customFilter(item, queryText) {
      let textlist = [];
      [
        "ref",
        "code",
        "label",
        "article_label",
        "article_code",
        "product_label",
        "product_code",
        "product_name",
        "ref_ext",
        "box",
        "rack",
        "nocmd",
        "date_cmd",
      ].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    bom_add(bom) {
      this.add_mode = true;
      bom.add_mode = true;
      this.alert = false;
      let l = bom.products.filter(
        (elm2) =>
          !bom.articles.map((elm) => elm.article_id).includes(elm2.id) &&
          elm2.stock_dispo > 0
      );
      if (l.length > 0) {
        bom.article_id = l[0].id;
        bom.qte =
          l[0].stock_dispo >= bom.besoin - this.qte_reserve(bom)
            ? parseFloat(bom.besoin - this.qte_reserve(bom))
            : parseFloat(l[0].stock_dispo);

        bom.stock_dispo = l[0].stock_dispo;
        bom.article_label =
          this.editedItem.type_id == "0" ? l[0].article_label : l[0].label;
        bom.article_code =
          this.editedItem.type_id == "0" ? l[0].article_code : l[0].code;
      }
      this.kp++;
      this.kp2++;
    },
    article_change(bom) {
      let i = bom.products.findIndex((elm) => elm.id == bom.article_id);
      if (i >= 0) {
        bom.stock_dispo = bom.products[i].stock_dispo;
        bom.article_label =
          this.editedItem.type_id == "0"
            ? bom.products[i].article_label
            : bom.products[i].label;
        bom.article_code =
          this.editedItem.type_id == "0"
            ? bom.products[i].article_code
            : bom.products[i].code;
      }
    },
    bom_save(bom) {
      if (this.$refs.form22.validate()) {
        bom.articles.push({
          article_id: bom.article_id,
          article_label: bom.article_label,
          article_code: bom.article_code,
          qte: bom.qte,
        });
        this.is_reserved = true;
        bom.qte_fais =
          (this.qte_reserve(bom) * this.editedItem.qte_lanc) / bom.besoin;
        this.qte_fais = Math.min.apply(
          Math,
          this.boms.map((elm) => elm.qte_fais)
        );
        bom.article_id = null;
        bom.qte = null;
        bom.stock_dispo = null;
        bom.article_label = null;
        bom.article_code = null;
        bom.add_mode = false;
        this.add_mode = false;
        this.kp++;
        this.kp2++;
      }
    },
    delete_article(bom, article) {
      let i = bom.articles.findIndex(
        (elm) => elm.article_id == article.article_id
      );
      if (i >= 0) {
        bom.articles.splice(i, 1);
        this.is_reserved = false;
        for (let index = 0; index < this.boms.length; index++) {
          const element = this.boms[index];
          if (element.articles.length > 0) {
            this.is_reserved = true;
            break;
          }
        }

        this.kp++;
        this.kp2++;
      }
    },
    bom_close(bom) {
      bom.article_id = null;
      bom.qte = null;
      bom.stock_dispo = null;
      bom.article_label = null;
      bom.article_code = null;
      bom.add_mode = false;
      this.add_mode = false;
      this.kp++;
      this.kp2++;
    },
    product_change(bom) {
      let qte = this.qte ? this.qte : 1;
      let j = this.templates.findIndex((elm) => elm.id == bom.fk_template);
      let c = 1;
      if (j >= 0) {
        bom.unit_name = this.templates[j].unit_name;
        bom.fk_unit_ratio = this.templates[j].fk_unit_ratio;
        bom.unit = this.templates[j].unit;

        if (bom.fk_unit_ratio != this.templates[j].fk_unit) {
          c = this.unit_convert(this.templates[j].fk_unit, bom.fk_unit_ratio);
        }
      }
      bom.besoin = ((bom.ratio / c) * qte).toFixed(2);
      bom.qte_fais = (this.qte_reserve(bom) * this.qte) / bom.besoin;

      this.qte_fais = Math.min.apply(
        Math,
        this.boms.map((elm) => elm.qte_fais)
      );
      this.kb++;
    },
    unit_convert(fk_unit, fk_unit2) {
      let f = 1;
      let i = this.units.findIndex((elm) => elm.id == fk_unit);
      if (i >= 0) {
        let cat_unit = this.units[i].category_id;

        let j = this.units.findIndex((elm) => elm.id == fk_unit2);
        if (j >= 0) {
          let cat_2 = this.units[j].category_id;
          if (cat_unit == cat_2) {
            let factor_unit_product = this.units[i].factor
              ? this.units[i].factor
              : 0;
            let factor_unit_2 = this.units[j].factor ? this.units[j].factor : 1;

            f = factor_unit_product / factor_unit_2;
          }
        }
      }
      return f;
    },

    async verif_faisabilite() {
      let isfaisable = true;
      if (this.editedItem.type_id == "1" && this.articles_sel.length == 0) {
        isfaisable = false;
        this.message = "Outputs introuvable pour ce produit";
        this.type_alert = "error";
        this.alert = true;
      }

      if (isfaisable) {
        if (
          this.boms.findIndex(
            (elm2) => this.qte_reserve(elm2) != elm2.besoin
          ) >= 0
        ) {
          this.alert = true;
          this.message =
            "Commande non faisable!!- Verifier les quantites reservées avec les besoins";
          this.type_alert = "error";
          isfaisable = false;
        }
      }
      return isfaisable;
    },
    verif_date(date1, days, date2) {
      var end = new Date(date1);
      end.setDate(end.getDate() + parseInt(days));
      var start = new Date(date2);
      return end <= start;
    },
    async get_boms() {
      if (this.editedItem.type_id == "1") {
        this.boms = [];
        let v = {
          product_id: this.product.produit_id,
        };
        this.listload = true;
        let r = await this.requette(PRODUCT_LIST_TRANSFORME, v);
        this.listload = false;
        if (r) {
          this.articles_outputs = r.product_output_transf;
        }
        this.qte_max = this.product.stock_dispo;
        let elm = {
          index: 0,
          add_mode: false,
          articles: [],
          products: this.product.articles,
          unit_name: this.product.unit,
          fk_template: this.product.fk_template,
          ratio: 1,
        };
        let i = this.templates.findIndex(
          (elm) => elm.id == this.product.fk_template
        );
        if (i >= 0) {
          elm.unit_name = this.templates[i].unit_name;

          elm.fk_unit_ratio = this.templates[i].fk_unit_ratio;
          elm.support = this.templates[i].support;
          let j = this.units.findIndex(
            (elm) => elm.id == this.templates[i].fk_unit_ratio
          );
          if (j >= 0) {
            elm.ratio_unit_name = this.units[j].name;
          }
        }
        this.boms.push(elm);
      } else if (this.product.boms) {
        // let i = 0;
        this.boms = JSON.parse(this.product.boms);
        let r = await this.requette(GETPRODUCTBOM, {
          product_id:
            this.code_app == 3 ? this.product.produit_id : this.product.id,
        });
        if (r) {
          let index = 0;
          this.boms.forEach((bom) => {
            bom.index = index;
            bom.add_mode = false;
            bom.articles = [];
            index++;
            let i = this.boms_list.findIndex(
              (elm) => elm.id == bom.fk_process_gamme_bom
            );

            if (i >= 0) bom.caption = this.boms_list[i].caption;
            i = this.templates.findIndex((elm) => elm.id == bom.fk_template);

            if (i >= 0) {
              bom.unit_name = this.templates[i].unit_name;

              bom.fk_unit_ratio = this.templates[i].fk_unit_ratio;
              bom.support = this.templates[i].support;
              let j = this.units.findIndex(
                (elm) => elm.id == this.templates[i].fk_unit_ratio
              );
              if (j >= 0) {
                bom.ratio_unit_name = this.units[j].name;
              }
            }
            bom.ratio = bom.ratio ? bom.ratio.toFixed(2) : 0;
            let bom_atts = bom.attributes;
            bom.products = [];
            let v_sort;
            r.getbomproduct
              .filter((elm) => elm.fk_template == bom.fk_template)
              .forEach((product) => {
                let product_att = JSON.parse(product.attributs_json);

                let ok = true;
                for (let index = 0; index < bom_atts.length; index++) {
                  const bom_att = bom_atts[index];

                  let i = product_att.findIndex(
                    (elm) => elm.attribute_id == bom_att.attribute_id
                  );

                  if (i == -1) {
                    ok = false;
                    break;
                  } else {
                    let v, v1;
                    if (bom_att.value) v = bom_att.value;
                    if (bom_att.value_id) v = bom_att.value_id;
                    if (product_att[i].value) v1 = product_att[i].value;
                    if (product_att[i].attribute_value_id)
                      v1 = product_att[i].attribute_value_id;
                    let k = this.attributes.findIndex(
                      (elm) => elm.id == bom_att.attribute_id
                    );

                    if (k >= 0) {
                      if (this.attributes[k].control == 1)
                        v_sort = bom_att.attribute_id;
                      if (
                        this.attributes[k].hyritage == 1 &&
                        parseFloat(v1) != parseFloat(v)
                      ) {
                        ok = false;
                        break;
                      }
                      if (
                        this.attributes[k].control == 1 &&
                        parseFloat(v1) < parseFloat(v)
                      ) {
                        ok = false;
                        break;
                      }
                    }
                  }
                }
                if (ok) bom.products.push(product);
              });
            if (bom.products.length > 0) {
              if (v_sort) bom.products.sort(sortByAttributeValue(v_sort));

              this.product_change(bom);
            }
          });

          let v;
          if (this.links.length == 0)
            v = {
              produit_id: [
                this.code_app == 3 ? this.product.produit_id : this.product.id,
              ],
            };
          else
            v = {
              article_id: this.links.map((elm) => elm.link_id),
            };
          r = await this.requette(ARTICLES, v);
          if (r) {
            this.stock_list = r.articles;
          }
          this.kp++;
          this.qte_fais = Math.min.apply(
            Math,
            this.boms.map((elm) => elm.qte_fais)
          );
        }
      }
    },
    addDays() {
      if (this.editedItem.delai) {
        let d = parseInt(this.editedItem.delai);

        var result = new Date(this.editedItem.date_order);
        result.setDate(result.getDate() + d);
        this.duedate = result.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else this.duedate = null;
    },

    datefr(date) {
      var result = new Date(date);
      return result.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },

    numberWithSpace: function (x) {
      return x != null
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    close() {
      this.$emit("close", this.editedItem);
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    async traiter_order() {
      if (this.$refs.form.validate()) {
        let ok = true;
        if (this.editedItem.statut_id == "6") {
          ok = await this.verif_faisabilite();
          //verifier reservation articles
        }
        if (ok)
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Etês-vous sûr de vouloir " +
                (this.editedItem.statut_id == "6" ? "valider" : "solder") +
                " cet OF"
            )
          ) {
            let s = this.editedItem.statut_id == "6" ? "7" : "9";
            this.save(s);
          }
      }
    },

    async save(statut_id) {
      if (this.$refs.form.validate()) {
        let ok = true;

        if (this.product.statut_tier == 3) {
          ok = false;
          this.message = "erreur tier bloqué";
          this.type_alert = "error";
          this.alert = true;
        }

        if (ok) {
          this.editedItem.statut_id = statut_id;
          this.modify = this.editer && this.editedItem.statut_id == "6";
          if (this.editedItem.id > 0) {
            let v = {
              order: {
                id: this.editedItem.id,
                order_ref: this.editedItem.order_ref,
                date_order: this.editedItem.date_order,
                qte_lanc: this.editedItem.qte_lanc,
                comment: this.editedItem.comment,
                statut_id: this.editedItem.statut_id,
                type_id: this.editedItem.type_id,
                gamme_id: this.editedItem.gamme_id,
                tol: this.editedItem.tol,
                delai: parseInt(this.editedItem.delai),
                write_uid: this.$store.state.me.id,
              },
            };
            await this.maj(UPDATE_ORDER, v);
          } else {
            let boms = [];
            let outputs = [];
            for (let index = 0; index < this.boms.length; index++) {
              const element = this.boms[index];
              element.articles.forEach((article) => {
                let i = boms.findIndex(
                  (e) => e.article_id == article.article_id
                );
                if (i >= 0) {
                  boms[i].qte = boms[i].qte + parseFloat(article.qte);
                } else
                  boms.push({
                    article_id: article.article_id,
                    qte: parseFloat(article.qte),
                  });
              });
            }
            this.articles_sel.forEach((element) => {
              outputs.push({
                article_id: element.article_id,
                ratio: element.ratio,
                caption: element.caption,
              });
            });
            let v = {
              order: [
                {
                  cmd_detail_id: this.code_app == 3 ? this.product.id : null,
                  order_ref: this.editedItem.order_ref,
                  date_order: this.$store.state.today,
                  qte_lanc: this.editedItem.qte_lanc,
                  comment: this.editedItem.comment,
                  statut_id: this.editedItem.statut_id,
                  type_id: this.editedItem.type_id,
                  gamme_id: this.editedItem.gamme_id,
                  produit_id: this.produit
                    ? this.produit.produit_id
                    : this.editedItem.type_id == "0"
                    ? this.product.id
                    : this.product.produit_id,
                  //tol: this.editedItem.tol,

                  delai: parseInt(this.editedItem.delai),
                  boms: boms,
                  outputs: this.editedItem.type_id == "1" ? outputs : null,
                },
              ],
            };
            let r = await this.maj(INSERT_ORDER, v);
            if (r) {
              this.editedItem.id = r.InsertOrder;
              this.$emit("add", this.editedItem);
            }
          }

          // if (this.editedItem.statut_id == "7") {
          //   // //boms
          //   // await this.insert_boms();
          //   // //gammes
          //   // await this.insert_gammes();
          // }
          this.$store.dispatch("Changed", true);
          this.close();
        }
      }
    },
  },
};
</script>
